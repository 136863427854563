/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import isFunction from 'lodash/isFunction';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { SketchPicker } from 'react-color';

import * as AppPropTypes from '../../lib/PropTypes';

import TextField from './TextField';

import styles from '../../styles/fields/color-field.module.css';

const propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    errors: AppPropTypes.formErrors,
    withBorder: PropTypes.bool,
    className: PropTypes.string,
    onChange: PropTypes.func,
    inputRef: AppPropTypes.ref,
};

const defaultProps = {
    name: null,
    value: null,
    placeholder: null,
    errors: null,
    withBorder: false,
    className: null,
    onChange: null,
    inputRef: null,
};

function ColorField({
    name,
    value,
    placeholder,
    errors,
    withBorder,
    className,
    onChange,
    inputRef,
}) {
    const onInputChange = useCallback(
        (newValue) => {
            if (onChange !== null) {
                onChange(newValue);
            }
        },
        [onChange],
    );
    const onPickerChange = useCallback(
        (newValue) => {
            if (onChange !== null) {
                onChange(newValue.hex);
            }
        },
        [onChange],
    );
    const [opened, setOpened] = useState(false);
    const onFocus = useCallback(() => {
        setOpened(true);
    }, []);

    const ref = useRef(null);
    useEffect(() => {
        if (!opened) {
            return () => {};
        }
        function onClick(e) {
            if (ref.current && !ref.current.contains(e.target)) {
                setOpened(false);
            }
        }
        document.addEventListener('click', onClick);
        return () => {
            document.removeEventListener('click', onClick);
        };
    }, [opened]);

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.opened]: opened,
                    [styles.withBorder]: withBorder,
                    [className]: className !== null,
                },
            ])}
            ref={ref}
        >
            <TextField
                name={name}
                placeholder={placeholder}
                value={value}
                onChange={onInputChange}
                onFocus={onFocus}
                // onBlur={onBlur}
                errors={errors}
                ref={inputRef}
                className={styles.input}
            />
            <div
                className={styles.color}
                style={{
                    background: value || '#fff',
                }}
            />
            <div className={styles.picker}>
                <SketchPicker color={value || '#fff'} onChange={onPickerChange} />
            </div>
        </div>
    );
}

ColorField.propTypes = propTypes;
ColorField.defaultProps = defaultProps;

export default React.forwardRef((props, ref) => <ColorField {...props} inputRef={ref} />);
