/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable no-restricted-globals */
import { useRoutes } from '@folklore/routes';
import PropTypes from 'prop-types';
import React from 'react';
import { Route, Switch } from 'wouter';

import DocumentShareImagePage from './pages/DocumentShareImagePage';
import DocumentSharePage from './pages/DocumentSharePage';

const propTypes = {
    location: PropTypes.string.isRequired,
    adsDisabled: PropTypes.bool,
};

const defaultProps = {
    adsDisabled: false,
};

function ShareRoutes({ location, adsDisabled: providedAdsDisabled, ...props }) {
    const routes = useRoutes();

    return (
        <Switch>
            <Route path={routes['document.share_card']}>
                {({ slug, variation }) => (
                    <DocumentShareImagePage slug={slug} variation={variation} />
                )}
            </Route>
            <Route path={routes['document.share']}>
                {({ slug }) => <DocumentSharePage slug={slug} />}
            </Route>
        </Switch>
    );
}

ShareRoutes.propTypes = propTypes;
ShareRoutes.defaultProps = defaultProps;

export default React.memo(ShareRoutes);
