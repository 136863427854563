/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import isFunction from 'lodash/isFunction';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef } from 'react';

import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../styles/fields/range-field.module.css';

const propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    step: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    placeholder: PropTypes.string,
    errors: AppPropTypes.formErrors,
    withBorder: PropTypes.bool,
    className: PropTypes.string,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    inputRef: AppPropTypes.ref,
};

const defaultProps = {
    name: null,
    value: null,
    min: null,
    max: null,
    step: null,
    placeholder: null,
    errors: null,
    withBorder: false,
    className: null,
    onChange: null,
    onFocus: null,
    onBlur: null,
    inputRef: null,
};

function RangeField({
    name,
    value,
    min,
    max,
    step,
    placeholder,
    errors,
    withBorder,
    className,
    onChange,
    onFocus,
    onBlur,
    inputRef,
}) {
    const internalRef = useRef(null);
    const onInputChange = useCallback(
        (e) => {
            internalRef.current.setCustomValidity('');
            const newValue =
                e.currentTarget.value !== '' ? parseFloat(e.currentTarget.value) : null;

            if (onChange !== null) {
                onChange(newValue);
            }
        },
        [onChange],
    );
    useEffect(() => {
        internalRef.current.setCustomValidity(
            errors !== null && errors.length > 0 ? errors[0] : '',
        );
        if (errors !== null && errors.length > 0) {
            internalRef.current.reportValidity();
        }
    }, [errors]);
    return (
        <input
            ref={(ref) => {
                internalRef.current = ref;
                if (inputRef !== null && isFunction(inputRef)) {
                    inputRef(ref);
                } else if (inputRef !== null) {
                    inputRef.current = ref;
                }
            }}
            type="range"
            name={name}
            value={value ?? ''}
            min={min}
            max={max}
            step={step}
            placeholder={placeholder}
            className={classNames([
                styles.container,
                {
                    [styles.withBorder]: withBorder,
                    [className]: className !== null,
                },
            ])}
            onChange={onInputChange}
            onFocus={onFocus}
            onBlur={onBlur}
        />
    );
}

RangeField.propTypes = propTypes;
RangeField.defaultProps = defaultProps;

export default React.forwardRef((props, ref) => <RangeField {...props} inputRef={ref} />);
